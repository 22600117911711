import React from "react";
import FrameContainer from "../Components/FrameContainer";
const FrameContainerExample = () => {
  return (
    <div className="flex">
      <div className="flex flex-col m-auto">
        <span className="font-black text-2xl mb-5">Lorem Is still cool!</span>
        <FrameContainer className={"p-5"} animated>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
          ducimus ut optio consequatur dolore maxime? Atque quam facilis et quo
          officiis ipsam, fugit quia, distinctio, expedita iste qui? Reiciendis,
          itaque?
        </FrameContainer>
      </div>
    </div>
  );
};

export default FrameContainerExample;
