import React from "react";
import AnimatedBlob from "../Components/AnimatedBlob";

const AnimatedBlobExample = () => {
  const colors = [
    "#222", // Very dark gray (almost black)
    "#555", // Dark gray
    "#888", // Medium gray
    "#AAA", // Gray
    "#CCC", // Light gray
    "#EEE", // Very light gray
    "#FFF", // White
  ];
  return (
    <div className="w-full h-[500px]">
      <AnimatedBlob colors={colors} />
    </div>
  );
};

export default AnimatedBlobExample;
