import React from "react";
import { motion } from "framer-motion";
const pathVariants = {
  initial: {
    d: "M37.7,-70.4C45.6,-60.8,46.5,-44.1,47.5,-31.2C48.6,-18.2,49.8,-9.1,54.1,2.5C58.4,14.1,65.8,28.2,62.6,37.3C59.3,46.3,45.4,50.4,33.2,56.2C21,62,10.5,69.5,0,69.5C-10.5,69.6,-21.1,62.2,-29.6,54.3C-38.2,46.4,-44.8,38.1,-51.7,28.9C-58.5,19.8,-65.5,9.9,-65,0.3C-64.5,-9.3,-56.5,-18.6,-49.8,-28.1C-43.2,-37.5,-37.9,-47,-29.8,-56.4C-21.8,-65.9,-10.9,-75.3,2,-78.8C14.9,-82.3,29.8,-79.9,37.7,-70.4Z",
  },
  animate: (i) => ({
    d: [
      "M39.9,-72.8C51,-62.8,58.6,-50.5,63.2,-38C67.7,-25.5,69.2,-12.8,71.6,1.4C74.1,15.6,77.5,31.2,73.1,43.9C68.7,56.7,56.5,66.6,43,68.4C29.5,70.2,14.7,64,1.2,61.8C-12.3,59.7,-24.5,61.7,-35.4,58.4C-46.4,55.1,-56,46.5,-63.5,35.9C-70.9,25.3,-76.3,12.6,-79.5,-1.9C-82.8,-16.4,-84,-32.8,-77.7,-45.6C-71.5,-58.4,-57.9,-67.6,-43.8,-75.8C-29.6,-84,-14.8,-91.2,-0.2,-90.9C14.5,-90.6,28.9,-82.9,39.9,-72.8Z",
      "M43.5,-76.8C56.4,-67.9,67,-56.3,68.3,-43.1C69.7,-29.8,61.8,-14.9,58.4,-2C54.9,11,56,21.9,54.6,35.1C53.2,48.3,49.4,63.7,39.9,73.1C30.4,82.5,15.2,86,2.2,82.2C-10.8,78.5,-21.7,67.4,-33.3,59.2C-44.8,50.9,-57.1,45.5,-64.2,36.1C-71.3,26.7,-73.2,13.3,-76,-1.7C-78.9,-16.7,-82.8,-33.3,-74.8,-41.2C-66.8,-49,-47,-48,-32.5,-56C-18.1,-64,-9,-81.1,3.1,-86.5C15.3,-91.9,30.6,-85.6,43.5,-76.8Z",
      "M31.4,-46.8C45.2,-46.5,64,-47.1,75.2,-39.5C86.4,-31.8,90.1,-15.9,90.6,0.3C91,16.4,88.2,32.9,79.2,44.3C70.2,55.8,55,62.3,40.7,66C26.5,69.7,13.2,70.5,-0.7,71.8C-14.7,73,-29.4,74.7,-40.1,69C-50.9,63.3,-57.8,50.3,-63.8,37.6C-69.8,24.9,-75,12.4,-75.8,-0.5C-76.6,-13.4,-73.1,-26.8,-65.5,-36.7C-57.9,-46.7,-46.2,-53.2,-34.6,-54.8C-23,-56.5,-11.5,-53.3,-1.3,-51C8.8,-48.7,17.6,-47.2,31.4,-46.8Z",
      "M43.5,-69.7C56,-68,65.7,-55.8,70.2,-42.4C74.8,-29,74.3,-14.5,68.9,-3.1C63.5,8.3,53.3,16.6,45.1,23.7C36.9,30.7,30.7,36.6,23.5,38.9C16.3,41.2,8.2,40,-0.5,40.7C-9.1,41.5,-18.1,44.3,-27.7,43.4C-37.3,42.5,-47.5,37.9,-59.1,30C-70.7,22.2,-83.8,11.1,-84.7,-0.5C-85.5,-12.1,-74.1,-24.1,-64.8,-35.9C-55.4,-47.7,-48.2,-59.3,-37.7,-62.2C-27.3,-65,-13.6,-59.2,0.9,-60.8C15.4,-62.4,30.9,-71.3,43.5,-69.7Z",
    ],
    transition: {
      duration: 10,
      delay: i * 0.5,
      ease: "easeInOut",
      repeat: Infinity,
      repeatType: "mirror",
    },
  }),
};

const AnimatedBlob = ({ colors, className }) => {
  return (
      <motion.svg
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        className={"w-full h-full "+className}
      >
        {colors.map((color, index) => (
          <motion.path
            stroke={color}
            fill="none"
            strokeWidth={"1px"}
            variants={pathVariants}
            custom={index}
            initial="initial"
            animate="animate"
            transform="translate(100 100)"
          />
        ))}
      </motion.svg>
  );
};

export default AnimatedBlob;