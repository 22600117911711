import React from "react";
import SideBar from "../Components/Nav/SideBar";
import ComponentContainer from "../Components/Containers/ComponentContainer";
import {componentsData} from "../Data/Components.js"
const ComponentsPage = () => {
  document.title = "KeiUI - Components"
  document.getElementsByTagName("meta")[3].content="Browse the selection of free components";
  document.getElementsByTagName("meta")[4].content="/Previews/OrbitingIcons.png";

  return (
    <div className="bg-zinc-900 min-h-screen">
      <div className={"max-w-screen-xl p-4  mx-auto pt-[8rem] flex-1 items-start lg:grid  lg:grid-cols-[240px_minmax(0,1fr)] lg:gap-10"}>
        <SideBar />
        <div className="flex flex-grow gap-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-20 pb-40 items-start relative z-40">
          {componentsData.map((component) => (
              <ComponentContainer
                key={component.id}
                title={component.title}
                imgPath={component?.thumbnail}
                subtitle={component.subtitle}
                componentId={component.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponentsPage;
