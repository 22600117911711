import React from "react";
import { OrbitingIcons, OrbitingObject } from "../Components/OrbitingIcons";
import { SiFramer, SiJavascript, SiReact, SiTailwindcss } from "react-icons/si";

import KeiuiLogo from "../../Assets/KeiuiLogo.svg";

const OrbitingIconsExample = () => {
  const radius = 150;
  const iconSize = 70;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-10">
      <span className="font-black text-4xl m-auto">Build cool things with KeiUI</span>
      <OrbitingIcons color="#99CC32" radius={radius} className="m-auto">
        <OrbitingObject
          radius={radius}
          iconSize={iconSize}
          startAngle={0}
          endAngle={1}
        >
          <div className="rounded-full border border-zinc-600 bg-zinc-800">
            <SiFramer className="p-4" fill="#99CC32" size={iconSize} />
          </div>
        </OrbitingObject>
        <OrbitingObject
          radius={radius}
          iconSize={iconSize}
          startAngle={1}
          endAngle={3}
        >
          <div className="rounded-full border border-zinc-600 bg-zinc-800">
            <SiReact className="p-4" fill="#99CC32" size={iconSize} />
          </div>
        </OrbitingObject>
        <OrbitingObject
          radius={radius}
          iconSize={iconSize}
          startAngle={3.14}
          endAngle={5}
        >
          <div className="rounded-full border border-zinc-600 bg-zinc-800">
            <SiTailwindcss className="p-4" fill="#99CC32" size={iconSize} />
          </div>
        </OrbitingObject>
        <div className="absolute inset-0 flex justify-center items-center ">
          <img src={KeiuiLogo} width={100} />
        </div>
      </OrbitingIcons>
    </div>
  );
};

export default OrbitingIconsExample;
