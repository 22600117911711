import React, { useState, useEffect } from 'react';
import {motion, AnimatePresence } from 'framer-motion';

class Point {
  constructor(x, y, z) {
    this.x = x;
    this.y = y;
    this.z = z;
  }
  // Projection Coordinates (Basically the position of the points on screen)
  pX(FOV) {return (this.x * FOV) / (this.z + FOV);}
  pY(FOV) {return (this.y * FOV) / (this.z + FOV);}
  p(FOV) {return [this.pX(FOV), this.pY(FOV), this.z];}

  // Rotation Formulas
  rotateX(theta) {
    const y = this.y * Math.cos(theta) - this.z * Math.sin(theta);
    const z = this.y * Math.sin(theta) + this.z * Math.cos(theta);
    this.y = y;
    this.z = z;
  }

  rotateY(theta) {
    const x = this.x * Math.cos(theta) + this.z * Math.sin(theta);
    const z = -this.x * Math.sin(theta) + this.z * Math.cos(theta);
    this.x = x;
    this.z = z;
  }

  rotateZ(theta) {
    const x = this.x * Math.cos(theta) - this.y * Math.sin(theta);
    const y = this.x * Math.sin(theta) + this.y * Math.cos(theta);
    this.x = x;
    this.y = y;
  }
}

class Vertex { 
  // Stores two points sharing a line between them
  constructor(p1, p2) {
    this.p1 = p1;
    this.p2 = p2;
  }

  getCoords(FOV) {
    return [this.p1.p(FOV), this.p2.p(FOV)];
  }
}

class Shape {
  constructor(vertices, FOV) {
    this.vertices = vertices;
    this.FOV = FOV;
  }

  getProjectedVertices() {
    return this.vertices.map(vertex => vertex.getCoords(this.FOV));
  }

  rotate(xtheta, ytheta, ztheta) {
    this.vertices.forEach(vertex => {
      vertex.p1.rotateX(xtheta);
      vertex.p1.rotateY(ytheta);
      vertex.p1.rotateZ(ztheta);
      vertex.p2.rotateX(xtheta);
      vertex.p2.rotateY(ytheta);
      vertex.p2.rotateZ(ztheta);
    });
  }
}

const RotatingCube = () => {
  const FOV = 10.0;

  const pointsCube = [
    new Point(-1, -1, -1),
    new Point(1, -1, -1),
    new Point(-1, 1, -1),
    new Point(1, 1, -1),
    new Point(-1, -1, 1),
    new Point(1, -1, 1),
    new Point(-1, 1, 1),
    new Point(1, 1, 1),
  ];

  const verticesCube = [
    new Vertex(pointsCube[0], pointsCube[1]),
    new Vertex(pointsCube[1], pointsCube[3]),
    new Vertex(pointsCube[3], pointsCube[2]),
    new Vertex(pointsCube[2], pointsCube[0]),
    new Vertex(pointsCube[4], pointsCube[5]),
    new Vertex(pointsCube[5], pointsCube[7]),
    new Vertex(pointsCube[7], pointsCube[6]),
    new Vertex(pointsCube[6], pointsCube[4]),
    new Vertex(pointsCube[0], pointsCube[4]),
    new Vertex(pointsCube[1], pointsCube[5]),
    new Vertex(pointsCube[2], pointsCube[6]),
    new Vertex(pointsCube[3], pointsCube[7]),
  ];

  const cube = new Shape(verticesCube, FOV);

  const [projectedVertices, setProjectedVertices] = useState(cube.getProjectedVertices());

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation(prev => prev + 1);

    }, 50);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const radians = (rotation * Math.PI) / 180;
    cube.rotate(radians, radians,0);
    setProjectedVertices(cube.getProjectedVertices());
  }, [rotation]);


  return (
      <svg viewBox='0 0 1000 1000' className="w-full h-full z-99">
      <AnimatePresence> 
          {projectedVertices.map(([p1, p2], index) => (
            <motion.line
              key={index}
              x1={p1[0] * 200 + 500}
              y1={p1[1] * 200 + 500}
              x2={p2[0] * 200 + 500}
              y2={p2[1] * 200 + 500}
              z={99}
              stroke="white"
              strokeWidth={10}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            />
          ))}
        </AnimatePresence>
      </svg>
  );
};

export default RotatingCube;
