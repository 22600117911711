import React, { useEffect } from "react";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";

function getCoordinates(r, d, s) {
  // Trig came in clutch for this
  //  r: radius of circle d: angle s: Size of icon
  let x = r * Math.sin(d) + r - s / 2;
  let y = r * Math.cos(d) + r - s / 2;

  return {
    x: Math.floor(x),
    y: Math.floor(y),
  };
}

export const OrbitingObject = ({
  radius,
  iconSize,
  duration = 2,
  startAngle = 0,
  endAngle = Math.PI,
  children
}) => {
  const angle = useMotionValue(startAngle);

  const x = useTransform(
    angle,
    (value) => getCoordinates(radius, value, iconSize).x
  );
  const y = useTransform(
    angle,
    (value) => getCoordinates(radius, value, iconSize).y
  );

  useEffect(() => {
    const controls = animate(angle, endAngle, {
      duration: duration,
      repeat: Infinity,
      repeatType: "mirror",
    });

    return controls.stop;
  }, [angle, duration]);

  return (
    <motion.div
      className={`absolute w-[${iconSize}px] h-[${iconSize}px]`}
      style={{
        x: x,
        y: y,
      }}
    >{children}</motion.div>
  );
};

export const OrbitingIcons = ({radius = 200, color='white', className, children}) => {
  
    return (
      <div
        className={'relative border rounded-full '+className}
        style={{ height: radius * 2, width: radius * 2, borderColor: color }}
      >
        {children}
      </div>
    );
}
