import React, { useState } from "react";
import { CopyBlock, nord } from "react-code-blocks";

const ExpandableCodeBlock = ({ code }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div
      className="relative overflow-hidden w-full"
      style={{ maxHeight: expanded ? "100%" : "350px" }}
    >
      <CopyBlock codeBlock text={code} theme={nord} language={"jsx"} />
      { !expanded &&

        <div className="absolute inset-0 flex flex-col justify-end bg-gradient-to-t from-neutral-800 to-transparent">
        <button
        className="mx-auto mb-10 p-3 border border-white rounded-lg"
        onClick={() => {
            setExpanded(true);
        }}
        >
        Expand
        </button>
        </div>

      }
    </div>
  );
};

export default ExpandableCodeBlock;
