import React from "react";
const Tag = ({ data }) => {
  return (<div className=" bg-neutral-50 text-neutral-700 dark:text-zinc-300 dark:bg-zinc-900 dark:border-zinc-800 border border-neutral-100 text-xs px-1 py-0.5 rounded-md capitalize">{data}</div>);
};

const TagsContainer = ({ tags }) => {
  return (
    <div className="flex flex-row gap-4">
      {tags.map((tag) => (
        <Tag data={tag} />
      ))}
    </div>
  );
};

export default TagsContainer;
