import "./App.css";
import NavBar from "./Components/Nav/NavBar";
import ComponentsPage from "./Pages/ComponentsPage";
import HeroPage from "./Pages/HeroPage";
import { Route, Routes } from "react-router-dom";
import TemplatesPage from "./Pages/TemplatesPage";
import ComponentDetailsPage from "./Pages/ComponentDetailsPage";
import TutorialPage from "./Pages/TutorialPage";
function App() {
  return (
    <div className="bg-zinc-900">
      <NavBar />
      <Routes>
        <Route path="/" element={<HeroPage />} />
        <Route path="/components" element={<ComponentsPage />} />
        <Route path="/components/:componentId" element={<ComponentDetailsPage />} />
        <Route path="/tutorial" element={<TutorialPage />} />
      </Routes>
    </div>
  );
}

export default App;
