import React, { useEffect, useRef, useState } from "react";
import { easeInOut, motion } from "framer-motion";

const Wave = () => {
  function getLeftPath(offsetY) { // Get the path of the svg with it's y offset by offsetY
    return `M5 ${452.5 + offsetY}C5 ${327.5 + offsetY} 277.5 ${
      305 + offsetY
    } 277.5 ${180 + offsetY}`;
  }
  function getRightPath(offsetY) { // Get the path of the svg with it's y offset by offsetY
    return `M277.5 ${452.5 + offsetY}C277.5 ${327.5 + offsetY} 5 ${
      305 + offsetY
    } 5 ${180 + offsetY}`;
  }
  const rightSpiral = [];
  const leftSpiral = [];
  const spiralGap = 70;

  const varientsLeft = {
    initial: (i) => ({
      d: getLeftPath(spiralGap * i),
    }),
    animate: (i) => ({
      d: [
        getLeftPath(spiralGap * i + 400), // Skrew Clockwise
        getLeftPath(spiralGap * i), // Skrew Counter Clockwise
        getLeftPath(spiralGap * i - 200 + i * -20), // Zoom Out Partially
        getLeftPath(spiralGap * i ), // Zoom In Full
        getLeftPath(spiralGap * i+i*10), // Twist
        getLeftPath(spiralGap * i +i*-10), // Twist Other way
        getLeftPath(spiralGap * i + 400), // Back to original
        getLeftPath(spiralGap * i + 200 + i * -15),// Full Zoom Out 
        getLeftPath(spiralGap * i ), // Full Zoom in
        getLeftPath(spiralGap * i + 400), // Back to Original
      ],
      transition: {
        duration: 15,
        repeat: Infinity,
        ease: easeInOut
      },
    }),
  };
  //  d: getRightPath(spiralGap*i+i*-20), Gives a twisting effect

  const varientsRight = {
    initial: (i) => ({
      d: getRightPath(spiralGap * i),
    }),
    animate: (i) => ({
      d: [
        getRightPath(spiralGap * i - 400), // Skrew Clockwise
        getRightPath(spiralGap * i), // Skrew Counter Clockwise
        getRightPath(spiralGap * i + 200 + i * -20), // Zoom Out Partially
        getRightPath(spiralGap * i), // Full Zoom in
        getRightPath(spiralGap * i +i*-10), // Twist
        getRightPath(spiralGap * i +i*10), // Twist Other way
        getRightPath(spiralGap * i - 400), // Back to original
        getRightPath(spiralGap * i - 200 + i * -15), // Full Zoom Out 
        getRightPath(spiralGap * i), // Full Zoom in
        getRightPath(spiralGap * i - 400), // Back to original
      ],
      transition: {
        duration: 15,
        repeat: Infinity,
        ease: easeInOut
      },
    }),
  };

  for (let i = -15; i < 15; i++) {
    rightSpiral.push(
      <motion.path
        key={i}
        d={getRightPath(spiralGap * i)}
        variants={varientsRight}
        initial="initial"
        animate="animate"
        custom={i}
        stroke="#a73bea"
        strokeWidth={5}
        strokeLinecap={"round"}
      />
    );
    leftSpiral.push(
      <motion.path
        key={i}
        d={getLeftPath(spiralGap * i)}
        variants={varientsLeft}
        initial="initial"
        animate="animate"
        custom={i}
        stroke="#139386"
        strokeWidth={5}
      />
    );
  }

  return (
    <div className="w-full h-full">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 282 641"
        fill="none"
        className=""
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>{rightSpiral}</g>
        {leftSpiral}
        <g>
          {/* Borders */}
          <path d="M5 0V633" stroke="white" />
          <path d="M277 0V633" stroke="white" />
        </g>
      </svg>
    </div>
  );
};

const WavyLines = () => {
  const gap = 10;
  return (
    <div className={`flex flex-col w-full h-96 gap-${gap}`}>
      <Wave />
    </div>
  );
};

export default WavyLines;
