import React from "react";
import SearchBar from "./SearchBar";
import { Link } from "react-router-dom";
import  KeiuiLogo from '../../Assets/KeiuiLogo.svg';

const NavBar = () => {
  return (
    <div className="fixed top-0 inset-x-0  bg-zinc-800 z-[99]">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <div className="flex flex-row gap-10">
          <Link to="/">
          <div className="flex flex-row gap-2">
            <img src={KeiuiLogo} width={35}  />
          <span className="text-white font-bold text-2xl">KeiUI</span>

          </div>
          </Link>

          <div className="flex flex-row gap-5 items-center">
            <Link to="/components">Components</Link>
            {/* <Link to="/tutorial">Tutorial</Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NavBar;
