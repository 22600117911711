import React from "react";
import RotatingCube from "../Components/RotatingCube";

const RotatingCubeExample = () => {
  return (
    <div className="relative flex flex-row w-full h-96">
        <div className="absolute inset-0 flex ">
          <span className="m-auto text-center text-6xl text-[#99CC32] font-black z-1">Cube<span className="text-[#965D25]">.</span></span>
        </div>
        <RotatingCube />
    </div>
  );
};

export default RotatingCubeExample;
