import React from "react";
import { CodeBlock, nord } from 'react-code-blocks';

const CodeBlockWindow = () => {
    const code = `
    // I love JavaScript!
    const word = 'Hello World!';
    console.log(word);
    return (<div></div>)
    
    `
    
  return (
    <div className="rounded-xl overflow-hidden">
      <div class="flex h-8 flex-row items-center space-x-2 bg-gray-50 dark:bg-[#12151D] px-2">
        <div class="h-3 w-3 rounded-full bg-red-500"></div>
        <div class="h-3 w-3 rounded-full bg-gray-300"></div>
        <div class="h-3 w-3 rounded-full bg-green-500"></div>
      </div>
      <CodeBlock
      text={code}
      language={'ts'}
      theme={nord}
      customStyle={{'border-radius': '0px'}}
      
    />
    </div>
  );
};

export default CodeBlockWindow;
