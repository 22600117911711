import raw from "raw.macro";
import FrameContainerExample from "./Examples/FrameContainerExample";
import AnimatedBlobExample from "./Examples/AnimatedBlobExample";
import OrbitingIconsExample from "./Examples/OrbitingIconsExample";
import FlapGridExample from "./Examples/FlapGridExample";
import WavyLinesExample from "./Examples/WavyLinesExample";
import RotatingCubeExample from "./Examples/RotatingCubeExample";

export const componentsData = [
    {
      "id": "animated-blob",
      "title": "Animated Blob",
      "subtitle": "The animation in Keions Hero Section",
      "tags": ["Animation", "SVG", "Hero", "Blob"],
      "thumbnail":"./Previews/AnimatedBlob.png",
      "preview": <AnimatedBlobExample/>,
      "previewCode": raw('./Examples/AnimatedBlobExample.jsx'),
      "classCode": raw('./Components/AnimatedBlob.jsx'),
      "classPath": './Components/AnimatedBlob.jsx'
    },
    {
      "id": "frame-container",
      "title": "Frame Container",
      "subtitle": "Frame off paragraphs on your website",
      "tags": ["Animation", "SVG", "Container"],
      "thumbnail":'./Previews/FrameContainer.png',
      "preview": <FrameContainerExample/>,
      "previewCode": raw('./Examples/FrameContainerExample.jsx'),
      "classCode": raw('./Components/FrameContainer.jsx'),
      "classPath": './Components/FrameContainer.jsx'
    },
    {
      "id": "orbiting-icons",
      "title": "Orbiting Icons",
      "subtitle": "An interesting way to show off some icons",
      "tags": ["Animation", "Container"],
      "thumbnail":'./Previews/OrbitingIcons.png',
      "preview": <OrbitingIconsExample/>,
      "previewCode": raw('./Examples/OrbitingIconsExample.jsx'),
      "classCode": raw('./Components/OrbitingIcons.jsx'),
      "classPath": './Components/OrbitingIcons.jsx'
    },
    {
      "id": "flap-grid",
      "title": "Flap Grid",
      "subtitle": "Inspired by vintage flap displays. Only works on Desktop currently",
      "tags": ["Animation", "Container", "Display"],
      "thumbnail":'./Previews/FlapGrid.png',
      "preview": <FlapGridExample/>,
      "previewCode": raw('./Examples/FlapGridExample.jsx'),
      "classCode": raw('./Components/FlapGrid.jsx'),
      "classPath": './Components/FlapGrid.jsx'
    },
    {
      "id": "wavy-lines",
      "title": "Wavy Lines",
      "subtitle": "A wavy background for your hero section",
      "tags": ["Animation", "Background", "Hero", "Special", "Complex"],
      "thumbnail":'./Previews/WavyLines.png',
      "preview": <WavyLinesExample/>,
      "previewCode": raw('./Examples/WavyLinesExample.jsx'),
      "classCode": raw('./Components/WavyLines.jsx'),
      "classPath": './Components/WavyLines.jsx'
    },
    {
      "id": "rotating-cube",
      "title": "Rotating Cube",
      "subtitle": "A rotating cube with a 3d renderer I created from scratch. ",
      "tags": ["Animation", "Background", "Hero", "Special", "Complex", "3D"],
      "thumbnail":'./Previews/RotatingCube.png',
      "preview": <RotatingCubeExample/>,
      "previewCode": raw('./Examples/RotatingCubeExample.jsx'),
      "classCode": raw('./Components/RotatingCube.jsx'),
      "classPath": './Components/RotatingCube.jsx'
    },
  ]
  