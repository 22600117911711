import React from 'react'
import {componentsData} from "../../Data/Components.js"
import { useNavigate } from 'react-router-dom'

const SideBarItem = ({title, onClick}) => {

  return (
  <a onClick={onClick} className='hover:text-zinc-400 text-white'>
    {title}
  </a>

  )
}

const SideBar = () => {
  const navigate = useNavigate();

  return (
    <div className="fixed top-28 z-30 hidden h-[calc(100vh-3.5rem)] shrink-0 lg:sticky lg:block lg:self-start">
      <div className="p-0 flex flex-col gap-2">
        <span className='font-bold pb-2'>All Components</span>
        {componentsData.map((component) => (
              <SideBarItem title={component.title} onClick={()=>{navigate(`/components/${component.id}`);}}/>
            ))}
      </div>
    </div>
  )
}

export default SideBar