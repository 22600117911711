import React from 'react'
import { useNavigate } from 'react-router-dom';

const ComponentContainer = ({title, subtitle, componentId, imgPath}) => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(`/components/${componentId}`);
    };
  return (
    <div onClick={handleClick} className='group flex flex-col w-full border-1 rounded-lg'>
        <div className='border rounded-lg border-white/[0.1] overflow-hidden group-hover:shadow-xl transition duration-200'>
            <img width='1024' height='720' className='' src={imgPath}/>
        </div>
        <span className='text-xl font-bold mt-4 mb-2 dark:text-neutral-100 text-neutral-700'>{title}</span>
        <p className='mt-2 text-sm font-normal dark:text-neutral-300 text-neutral-500'>{subtitle}</p>

    </div>
  )
}

export default ComponentContainer