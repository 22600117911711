import React from "react";
import WavyLines from "../Components/WavyLines";

const WavyLinesExample = () => {
  return (
    <div className="relative flex w-full">
      <WavyLines />
      <div className="absolute inset-0 flex">
        <div className="m-auto flex flex-col text-center gap-5">
          <span className="font-black text-5xl">Wavy Lines</span>
          <span className="font-bold text-xl">
            This isn't 3d, just an optical illusion.
          </span>
        </div>
      </div>
    </div>
  );
};

export default WavyLinesExample;
