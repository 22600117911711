import React, { useState } from "react";
import { useParams } from "react-router-dom";
import SideBar from "../Components/Nav/SideBar";
import { componentsData } from "../Data/Components.js";
import TagsContainer from "../Components/Containers/TagsContainer";
import { CopyBlock, nord } from "react-code-blocks";
import ExpandableCodeBlock from "../Components/Containers/ExpandableCodeBlock.jsx";

const ComponentDetailsPage = () => {
  const { componentId } = useParams();
  const component = componentsData.find((comp) => comp.id === componentId);
  const npmCode = `npm install --save framer-motion tailwind`;
  const [previewMode, setPreviewMode] = useState(0); // 0: Preview, 1: Preview Code

  document.title = "KeiUI - "+ component?.title
  document.getElementsByTagName("meta")[3].content=component?.subtitle;
  document.getElementsByTagName("meta")[4].content=component?.thumbnail.substring(1)

  return (
    <div className="bg-zinc-900 min-h-screen">
      <div className="max-w-screen-xl p-4  mx-auto pt-[8rem] flex-1 items-start lg:grid  lg:grid-cols-[240px_minmax(0,1fr)] lg:gap-10">
        <SideBar />
        <div className="flex flex-grow flex-col gap-2">
          <span className="font-black text-4xl">{component?.title}</span>
          <span className="text-lg text-muted-foreground">
            {component?.subtitle}
          </span>
          {component?.tags ? <TagsContainer tags={component?.tags} /> : null}

          <div className="pt-10 space-y-5">
            <div className="flex flex-row gap-5">
              <button
                className={"p-2 font-bold px-8 rounded-lg " + (previewMode==0 ? "bg-zinc-700 text-white" : "bg-transparent text-zinc-500")}
                onClick={() => {
                  setPreviewMode(0);
                }}
              >
                Preview
              </button>
              <button
                className={"p-2 font-bold px-8 rounded-lg " + (previewMode==1 ? "bg-zinc-700 text-white" : "bg-transparent text-zinc-500")}
                onClick={() => {
                  setPreviewMode(1);
                }}
              >
                Code
              </button>
            </div>
            <div className="flex border rounded-lg h-full min-h-[350px] p-10">
              {previewMode === 0 ? (
                component?.preview
              ) : (
                <ExpandableCodeBlock code={component?.previewCode}/>
              )}
            </div>
          </div>

          <span className="font-black text-4xl pt-10">How to install</span>
          <span className="font-black text-2xl pt-10">
            Install Dependencies
          </span>
          <span>Make sure you have the dependencies installed</span>
          <CopyBlock
            codeBlock
            text={npmCode}
            theme={nord}
            language={"sh"}
            showLineNumbers={false}
          />
          <span className="font-black text-2xl pt-10">Create file</span>
          <span>
            Create a file <code>{component.classPath}</code>
          </span>
          <ExpandableCodeBlock code={component?.classCode} />
        </div>
      </div>
    </div>
  );
};

export default ComponentDetailsPage;
