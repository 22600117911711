import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export const FlapContainer = ({
  className,
  children,
  letters = "abcdef",
  time = 1,
}) => {
  const [currIndex, setCurrIndex] = useState(0);
  const [currLetter, setCurrLetter] = useState(letters[0]);
  const [nextLetter, setNextLetter] = useState(letters[1]);

  // Effect to update letters and trigger animation
  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currIndex + 1) % letters.length;
      setCurrLetter(letters[nextIndex]);
      setCurrIndex(nextIndex);
      setNextLetter(letters[(nextIndex + 1) % letters.length]);
    }, time * 1000);

    return () => clearInterval(interval);
  }, [currIndex]);

  useEffect(() => {
    console.log('Inside useEffect:', letters, currIndex, currLetter);
  }, [currIndex]);

  // Effect to trigger animation when letters change

  return (
    <div
      className={
        "relative row-span-1 rounded-xl bg-slate-800 font-custom border" + className
      }
    >
      {children}

      {/* [Static] Top and bottom half of letters */}
      <div className="absolute inset-0 h-1/2 overflow-hidden">
        {/* ONLY GIVE TOP HALF OF LETTER */}
        <span className="text-[13em] block text-center">{nextLetter}</span>
      </div>

      <div className="absolute inset-0 h-1/2 top-1/2 overflow-hidden">
        {/* ONLY GIVE BOTTOM HALF OF LETTER */}
        <span className="relative text-[13em] block -top-full text-center ">
          {currLetter}
        </span>
      </div>

      {/* [Cosmetic] Spacer and borders */}
      <div className="absolute inset-x-0 inset-y-1/2 z-50 border border-slate-700"/>
      <div className="absolute inset-0 z-50 border rounded-xl border-slate-700"/>

      {/* [Animated] ONLY GIVE TOP HALF OF LETTER */}
      <motion.div
        key={currIndex} // Ensure animation re-triggers when letter changes
        className="bg-slate-800 rounded-xl absolute h-1/2 inset-0 origin-bottom overflow-hidden "
        initial={{ rotateX: "0deg" }}
        animate={{ rotateX: "-90deg" }}
        transition={{ duration: time / 2 }}
      >
        <span className="text-[13em] block text-center">{currLetter}</span>
      </motion.div>

      {/* [Animated] ONLY GIVE BOTTOM HALF OF LETTER */}
      <motion.div
        key={currIndex+1} // Ensure animation re-triggers when letter changes. Increment ensures that there isn't a rendered duplicate key
        className="bg-slate-800 rounded-xl absolute inset-0 h-1/2 top-1/2 origin-top overflow-hidden "
        animate={{ rotateX: "0deg" }}
        initial={{ rotateX: "90deg" }}
        transition={{ delay: time / 2, duration: time / 2 }}
      >
        <span className="relative text-[13em] block -top-full text-center ">
          {nextLetter}
        </span>
      </motion.div>
    </div>
  );
};

export const FlapGrid = ({ className, children }) => {
  return (
    <div
      className={
        "hidden md:auto-rows-[18rem] md:grid-cols-3 md:grid grid-cols-1  gap-4 max-w-7xl mx-auto " +
        className
      }
    >
      {children}
    </div>
  );
};
