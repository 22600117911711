import React from "react";
import CodeBlockWindow from "../Components/Containers/CodeBlockWindow";
import { Link } from "react-router-dom";

const HeroPage = () => {
  document.title = "KeiUI"
  document.getElementsByTagName("meta")[3].content="An Open-Source Library for React.js";

  return (
    <div className="bg-amber-400 h-[40rem] pt-20">
      <div className="max-w-screen-xl h-full flex flex-row mx-auto p-4 ">
        <div className="flex flex-col w-full justify-center gap-10">
          <span className="text-zinc-800 font-black text-7xl">
            Beauty is just <br />
            Copy + Paste
          </span>
          <span>
            Simply copy and paste our components without the worry of styling,
            animations, or heavy dependencies.
          </span>
          <div>
            <div className="bg-white text-zinc-800 p-4 rounded-lg inline-block">
              <Link to="/components">Browse Components</Link>
            </div>
          </div>
        </div>
        <div className="flex w-full items-center">
          <div className="w-full  hidden lg:block ">
            <CodeBlockWindow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
