import React from 'react'
import { FlapGrid, FlapContainer } from '../Components/FlapGrid.jsx'



const FlapGridExample = () => {
  return (
    <div className='w-[70%] mx-auto'>
      <div className='inline-flex w-full text-center m-auto'>
        <span className='text-center m-auto font-bold text-xl mb-5'>
          Time I have wasted on this project.
        </span>
        </div>
        <FlapGrid >
            <FlapContainer letters="0030010000000u" />
            <FlapContainer letters="1501421237123g" />
            <FlapContainer letters='mmmhhhddddwwwh' />
        </FlapGrid>


    </div>
  )
} 

export default FlapGridExample